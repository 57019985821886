import React, { useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'

import { LangContext } from '../Translation'
import { useGlobalContext } from '../../components/AppContext'
import translations from './translations'
import './index.css'
import Icon from '../Icon'

const { Sider } = Layout
const { SubMenu } = Menu

export default function AppNavbar(props) {
	const lang = React.useContext(LangContext)(translations)
	const location = useLocation()
	const pathname = location.pathname
	const { keycloak } = useKeycloak()
	const { isTheUserHealthServiceProvider } = useGlobalContext()

	const [collapsed, setCollapsed] = useState(true)
	const [openKeys, setOpenKeys] = useState()

	const handleOpen = (c) => {
		setOpenKeys(c.slice(-1))
	}

	const renderMenuContent = (menu) => {
		return menu.map((item) => {
			if (item.hidden) return null
			return item.children ? (
				<SubMenu key={item.title} icon={item.icon} title={item.title} className="demo">
					{item.children.map((c) => {
						if (c.hidden) return null
						return (
							<Menu.Item key={c.title + '_link'} disabled={c.disabled}>
								<div className={`circle ${c.path === pathname ? 'active-link-circle' : ''}`}></div>
								<Link key={c.title} to={c.path} className="sub-item-link">
									{c.title}
								</Link>
							</Menu.Item>
						)
					})}
				</SubMenu>
			) : (
				<Menu.Item key={item.title} icon={item.icon}>
					<Link key={item.title} to={item.path}>
						{item.title}
					</Link>
				</Menu.Item>
			)
		})
	}

	const menuContent = renderMenuContent(props.routes)

	return (
		<Sider collapsible collapsed={collapsed} trigger={null} theme="light" width="280">
			<div className={`${collapsed ? 'logo-collapsed' : 'sidebar-logo'}`}>
				<div onClick={() => setCollapsed(!collapsed)}>
					<Icon type={`${collapsed ? 'compress' : 'expand'}`} />
				</div>
				<Link to="/">
					<Icon type={'logo'} />
				</Link>
			</div>
			<div className="menu-container">
				<Menu theme="light" mode="inline" openKeys={openKeys} onOpenChange={handleOpen}>
					{menuContent}
					<Menu.Item
						key={'help'}
						onClick={() => {
							return
						}}
						icon={<Icon type={'help'} />}
						className="sider-menu-action sub">
						<a
							target="_blank"
							rel="noreferrer"
							href={isTheUserHealthServiceProvider() ? process.env.REACT_APP_HSP_HELP_PAGE : process.env.REACT_APP_PROJECT_HELP_PAGE}>
							{lang('help')}
						</a>
					</Menu.Item>
					<Menu.Item icon={<Icon type={'report-a-bug'} />} key={'report-a-bug'}>
						<Link to="/bugReport">{lang('report-a-bug')}</Link>
					</Menu.Item>
					<Menu.Item
						onClick={() => {
							keycloak.logout()
						}}
						key={'logout'}
						icon={<Icon type={'logout'} />}
						className="sider-menu-action"
						style={{ marginLeft: '2px' }}>
						{lang('logout')}
					</Menu.Item>
				</Menu>
			</div>
		</Sider>
	)
}
