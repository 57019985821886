import Keycloak from 'keycloak-js'
import config from './kcConfig'

const keycloak = new Keycloak({
	realm: config.users.realm,
	url: config.users.url,
	onLoad: 'check-sso',
	flow: 'implicit',
	silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
	clientId: config.users.clientId,
	checkLoginIframe: config.users.checkLoginIframe,
	resource: config.users.resource,
	confidentialPort: config.users.confidentialPort,
	requireHttps: config.users.requireHttps
})

export default keycloak
