import { Form, Upload, Select, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'

import { toast } from 'react-toastify'

import { createKey, createUpload, getAvailableCategories } from '../../api-new'
import { encryptMessage } from '../../encryption'

import './index.css'
import translations from './translations'
import { LangContext } from '../../components/Translation'
import Backend from '../../api/Backend'
import { useKeycloak } from '@react-keycloak/web'

const { Option } = Select

export const UploadSender = ({ form, patientId, completion }) => {
	const { keycloak } = useKeycloak()
	const lang = React.useContext(LangContext)(translations)
	const backend = new Backend()

	const [categories, setCategories] = useState([])
	const [category, setCategory] = useState()
	const [file, setFile] = useState()

	useEffect(() => {
		const initialize = async () => {
			const response = await getAvailableCategories(keycloak.token)
			setCategories(response)
		}

		initialize()
	}, [])

	const handleFileUpload = async () => {
		if (!category || !file) {
			toast.error(lang('empty-fields'))
			return
		}

		try {
			let data
			const reader = new FileReader()
			reader.onload = (e) => {
				data = e.target.result
			}
			reader.readAsArrayBuffer(file)

			backend.careTeam({
				cb: async (response) => {
					const teams = response.resultObject.data
					const sharedWith = [patientId]
					teams.forEach((team) => {
						const mappedUsers = team.resource.participant.map((element) => element.user_id)
						sharedWith.push(...mappedUsers)
					})

					const key = await createKey(keycloak.token, sharedWith)
					const encryptedMessage = await encryptMessage(key.key, data)
					const options = {
						...encryptedMessage.options,
						iv: Array.from(encryptedMessage.options.iv),
						salt: Array.from(encryptedMessage.options.salt)
					}
					await createUpload(keycloak.token, key.id, encryptedMessage.data, file.name, 'scrypt', options, sharedWith, category)
					toast.success(lang('upload-file-success'))
					setCategory(undefined)
					setFile(undefined)
					if (completion) {
						completion()
					}
				}
			})
		} catch (error) {
			console.log(error)
			toast.error(lang('an-error-has-occured'))
		}
	}

	return (
		<>
			<Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }} autoComplete="off">
				<Form.Item name="category" label={lang('category')}>
					<Select
						onChange={(value) => {
							setCategory(value)
						}}>
						{categories
							? categories.map((e) => {
									return (
										<Option key={e} value={e}>
											{e}
										</Option>
									)
							  })
							: []}
					</Select>
				</Form.Item>
				<Form.Item name="file" label={lang('choose-file')}>
					<Upload
						showUploadList={false}
						beforeUpload={(file) => {
							setFile(file)
							// Prevent upload
							return false
						}}>
						<Input readOnly={true} placeholder={file ? file.name : ''}></Input>
					</Upload>
				</Form.Item>
			</Form>

			<Button style={{ width: '100%' }} antType="primary" txt={lang('send')} onClick={handleFileUpload} />
		</>
	)
}
