import axios from 'axios'

const keyHubUrl = process.env.REACT_APP_SECRET_SERVICE_URL
const healthHubUrl = process.env.REACT_APP_HEALTH_SERVICE_URL
const tenant = process.env.REACT_APP_TENANT

// common

const makeRequest = async (accessToken, url, method, body) => {
	var response
	if (method === 'POST' || method === 'post') {
		response = await axios.post(url, body, { validateStatus: false, headers: { Authorization: `Bearer ${accessToken}` } })
	} else {
		response = await axios({ method: method, url: url, validateStatus: false, headers: { Authorization: `Bearer ${accessToken}` } })
	}
	const responseBody = response.data
	return responseBody
}

const checkAuthentication = async (accessToken, baseUrl, userId) => {
	try {
		const response = await makeRequest(accessToken, `${baseUrl}/v1/${tenant}/auth/me`, 'GET', null)
		if (userId !== response.id) {
			console.log(`${userId} expected, got ${response.id}`)
		}
	} catch (error) {
		console.error(error)
	}
}

export const checkKeyHubAuthentication = async (accessToken, userId) => {
	await checkAuthentication(accessToken, keyHubUrl, userId)
}

export const checkHealthHubAuthentication = async (accessToken, userId) => {
	await checkAuthentication(accessToken, healthHubUrl, userId)
}

// Health service
// Uploads

export const getUploadsSharedWithMe = async (accessToken, id, page = 0) => {
	return await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/uploads/shared/by/${id}?` + new URLSearchParams({ page: page }), 'GET', null)
}

export const getUploadsSharedWith = async (accessToken, id, page = 0) => {
	return await makeRequest(
		accessToken,
		`${healthHubUrl}/v1/${tenant}/uploads/shared/with/${id}?` + new URLSearchParams({ page: page }),
		'GET',
		null
	)
}

export const getUploadsAccessible = async (accessToken, id, page = 0) => {
	return await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/uploads/shared/all/${id}?` + new URLSearchParams({ page: page }), 'GET', null)
}

export const getUpload = async (accessToken, id) => {
	return await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/uploads/${id}`, 'GET', null)
}

export const createUpload = async (accessToken, keyId, data, filename, derivation, options, sharedWith, category) => {
	return await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/uploads`, 'POST', {
		keyId: keyId,
		data: data,
		filename: filename,
		derivation: derivation,
		options: options,
		sharedWith: sharedWith,
		category: category
	})
}

export const getAvailableCategories = async (accessToken) => {
	return await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/uploads/categories/available`)
}

// Appointments

export const getPatientAppointments = async (accessToken, patientId) => {
	return await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/users/${patientId}/appointments`)
}

// Forms

export const assignForm = async (accessToken, patientId, formId) => {
	await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/users/${patientId}/forms/${formId}/assignments`, 'POST')
}

export const getLatestFormAnswers = async (accessToken, patientId, formId) => {
	const result = await makeRequest(accessToken, `${healthHubUrl}/v1/${tenant}/users/${patientId}/forms/${formId}/assignments/latest/answers`)
	return result
}

// Keys service

export const getKey = async (accessToken, id) => {
	return await makeRequest(accessToken, `${keyHubUrl}/v1/${tenant}/keys/${id}`, 'GET', null)
}

export const createKey = async (accessToken, sharedWith) => {
	return await makeRequest(accessToken, `${keyHubUrl}/v1/${tenant}/keys`, 'POST', { sharedWith })
}

// Heart rate

export const getHeartRateExtremumsDaily = async (accessToken, patientId, startDate, endDate, timezone) => {
	return await makeRequest(
		accessToken,
		`${healthHubUrl}/v1/${tenant}/users/${patientId}/heart-rate-extremums-daily?` +
			new URLSearchParams({ startDate: startDate, endDate: endDate, timezone: timezone }),
		'GET',
		null
	)
}

// Fitbit daily reports

export const getDailyReports = async (accessToken, patientId, startDate, endDate) => {
	return await makeRequest(
		accessToken,
		`${healthHubUrl}/v1/${tenant}/users/${patientId}/daily-report?` + new URLSearchParams({ startDate: startDate, endDate: endDate }),
		'GET',
		null
	)
}
